function playDrums(){
    document.getElementById('myVideo').muted = !document.getElementById('myVideo').muted
    document.getElementById('unmute').style = "display:none"
    document.getElementById('mute').style = "display:flex"
    document.getElementById('controls').style = "z-index:9999"
}
function muteDrums(){
    document.getElementById('myVideo').muted = !document.getElementById('myVideo').muted
    document.getElementById('mute').style = "display:none"
    document.getElementById('unmute').style = "display:flex"
    document.getElementById('controls').style = "z-index:-9999"
}